import { ref, computed, watch } from '@vue/composition-api'
import { avatarText, distance, currency, capitalize } from '../../../utils/filter'

import store from '@/store'
import i18n from '@/libs/i18n'
import useAPI from '../../../utils/useAPI'
import SearchFilter from '../../../components/searchFilterV2/SearchFilter'
import CardMovement from '../../../components/card/Movement'

export default {
  components: {
    SearchFilter,
    CardMovement
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const sortOptions = ref([])

    const columns = ref([
      {
        display: capitalize(i18n.tc('employee')),
        key: '_employee',
        sortable: true
      },
      {
        display: capitalize(i18n.t('title')),
        key: 'title',
        sortable: true
      },
      {
        display: capitalize(i18n.t('date')),
        key: 'startDate',
        sortable: true,
        isDate: true
      },
      {
        display: capitalize(i18n.t('startPlace')),
        key: 'startPlace',
        sortable: true
      },
      {
        display: capitalize(i18n.t('endPlace')),
        key: 'endPlace',
        sortable: true
      },
      {
        display: capitalize(i18n.t('distance')),
        key: 'distance',
        sortable: true
      },
      {
        display: capitalize(i18n.t('estimatedCost')),
        key: 'estimatedCost',
        sortable: true
      },
    ])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const movements = computed(() => {
      return store.getters['event2/getEvents']
      // return store.getters['event2/getMovements']
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      fetchEvents2,
    } = useAPI()

    const getEstimated = mvts => {
      let output = 0
      mvts.forEach(s => output += parseFloat(s.mileageAllowanceCost))

      return output
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    // fetchEvents({start: null, end: null}, 'trip')
    fetchEvents2({
      filters: [{
        sectionLogicOperator: 'AND',
        sectionFilters: [
          {
            logicOperator: "AND",
            operator: 'equal',
            field: 'type',
            value: 'trip'
          },
          {
            logicOperator: "AND",
            operator: 'equal',
            field: 'isTripWithPersonalVehicle',
            value: 'true'
          }
        ]
      }]
    })

    return {
      // Components
      avatarText,
      distance,
      currency,

      // Data
      sortOptions,
      columns,
      // additionalSortOptions,
      // tableColumns,
      // selectedFilters,

      // Computed
      movements,

      // Methods
      getEstimated,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}