<template>
  <b-button
      class="customButton text-nowrap"
      v-ripple.400="ripple"
      :variant="variant"
      :size="size"
      @click="$emit('click')"
      @click.middle="$emit('middleClick')"
      :style="title==''?(size=='sm'?'padding: 0.3rem 0.5rem;':'padding: 0.5rem; margin-top:3px'):''"
      :block="block"
      :disabled="isLoading||isDisabled"
      :class="{'cursor-not-allowed': isDisabled}"
  >

    <div class="d-flex justify-content-center">
      <b-spinner v-if="isLoading" small class="mr-50" />

      <icon v-if="withIcon && icon != ''"
            :icon="icon"
            :library="iconLibrary"
            :class="{'mr-50':title != ''}"/>

      <slot name="title"><span class="text-truncate" v-if="title != ''">{{ capitalize($t(title)) }}</span></slot>
    </div>
  </b-button>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '@/utils/filter'

import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {},
  props: {
    variant: {
      type: String,
      default: 'primary'
    },
    title: {
      type: String,
      default: ''
    },
    withIcon: {
      type: Boolean,
      default: true
    },
    icon: {
      type: String,
      default: ''
    },
    iconLibrary: {
      type: String,
      default: 'fas'
    },
    size: {
      type: String,
      default: 'md'
    },
    block: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const ripple = computed(() => {
      if (props.variant.includes("outline")) {
        if (props.variant.includes("secondary")) {
          return "rgba(186, 191, 199, 0.15)"
        } else if (props.variant.includes("success")) {
          return "rgba(40, 199, 111, 0.15)"
        } else if (props.variant.includes("danger")) {
          return "rgba(234, 84, 85, 0.15)"
        } else if (props.variant.includes("warning")) {
          return "rgba(255, 159, 67, 0.15)"
        } else if (props.variant.includes("info")) {
          return "rgba(0, 207, 232, 0.15)"
        } else if (props.variant.includes("dark")) {
          return "rgba(30, 30, 30, 0.15)"
        } else {
          return "rgba(113, 102, 240, 0.15)"
        }
      } else {
        return "rgba(255, 255, 255, 0.15)"
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data

      // Computed
      ripple,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">
</style>