var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('search-filter',{attrs:{"items":_vm.movements,"isActionColumnEnabled":false,"itemView":"list","sortOptions":_vm.sortOptions,"columns":_vm.columns,"show-past-years-filter":true,"allowAdd":false},scopedSlots:_vm._u([{key:"resultInfos",fn:function(ref){
var items = ref.items;
return [_vm._v(" ("+_vm._s(items.length)+") "),_c('span',[_vm._v(_vm._s(_vm.$t('estimated:') + ' ' + _vm.currency(_vm.getEstimated(items)))+" ")])]}},{key:"gridView",fn:function(ref){
var item = ref.item;
return [_c('card-movement',{attrs:{"movement":item}})]}},{key:"listView_cell_distance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.distance(item.distance))+" ")]}},{key:"listView_cell_estimatedCost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.currency(item.mileageAllowanceCost))+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }